import React, { Component } from "react";
import DetailContrat from "../components/detailContrat";
import I18n from "../components/I18n";
import I18NContext from "../components/I18NContext";
import Configuration from "../components/Configuration";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { I18n: I18n, reloaded: false };
  }

  componentDidMount() {
    const codeLangue = this.getLanguage();

    this.state.I18n.changeLanguage(codeLangue);
  }

  getLanguage() {
    var listeNomPossibleFr = Configuration.obtenirConfigurations().LISTE_URL_FR;
    var currentUrl = window.location.origin;
    return listeNomPossibleFr.indexOf(currentUrl) > -1 ? "fr" : "en";
  }

  render() {
    return (
      <React.Fragment>
        <I18NContext.Provider value={this.state.I18n}>
          <DetailContrat cookies={this.props.cookies} />
        </I18NContext.Provider>
      </React.Fragment>
    );
  }
}

export default Index;
