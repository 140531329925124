import React from 'react';
import ValeurAvecAucunCache from './valeurAvecAucunCache';
import PartiesLiees from './partiesLiees';
import I18nConfig from "./I18n";

export default class InfosPoliceRenteServie extends React.Component {

    render () {

      var sectionCoPreneurs = "";
      if (this.props.coPreneurs && this.props.coPreneurs.length > 0) {
        sectionCoPreneurs = <li className="ligneInfo">
                                <span className="ligneInfo sPartiesLiees">
                                    <label id="lblCoPreneurs" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:CoPreneurs")}</b></label>
                                </span>
                                <PartiesLiees listeValeurs={this.props.coPreneurs} typePartiesLiees="CoPreneur" />
                            </li>
      }
      var sectionCessionnaires = "";
      if (this.props.cessionnaires && this.props.cessionnaires.length > 0) {
          sectionCessionnaires = <li className="ligneInfo">
                                    <span className="ligneInfo sPartiesLiees">
                                        <label id="lblCessionnaires" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Cessionnaire")}</b></label>
                                    </span>
                                    <PartiesLiees listeValeurs={this.props.cessionnaires} typePartiesLiees="Cessionnaire" />
                                </li>
      }
      var sectionFondations = "";
      if (this.props.fondations && this.props.fondations.length > 0) {
        sectionFondations = <li className="ligneInfo">
                                    <span className="ligneInfo sPartiesLiees">
                                        <label id="lblFondations" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Fondations")}</b></label>
                                    </span>
                                    <PartiesLiees listeValeurs={this.props.fondations} typePartiesLiees="Fondation" />
                                </li>
      }
      var sectionRentiers = "";
      if (this.props.rentier && this.props.rentier.length > 0) {      
        sectionRentiers = <li className="ligneInfo">
                                <span className="ligneInfo sPartiesLiees">
                                    <label id="lblRentier" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Rentier")}</b></label>
                                </span>
                                <ul className="unstyled ulPartiesLiees">
                                    <li className="liPartiesLiees" id="liRentier_0">{this.props.rentier}</li>
                                </ul>
                            </li>
      }
      var sectionConjoints = "";
      if (this.props.conjoints && this.props.conjoints.length > 0) {
        sectionConjoints = <li className="ligneInfo">
                                <span className="ligneInfo sPartiesLiees">
                                    <label id="lblConjoint" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Corentier")}</b></label>
                                </span>
                                <PartiesLiees listeValeurs={this.props.conjoints} typePartiesLiees="Conjoint" />
                            </li>
      }
      var sectionFiduciaires = "";
      if (this.props.fiduciaires && this.props.fiduciaires.length > 0) {
        sectionFiduciaires = <li className="ligneInfo">
                                <span className="ligneInfo sPartiesLiees">
                                    <label id="lblFiduciaire" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Fiduciaires")}</b></label>
                                </span>
                                <PartiesLiees listeValeurs={this.props.fiduciaires} typePartiesLiees="Fiduciaire" />
                            </li>
      }

      var contientPartiesLiees = false;
      if (sectionCoPreneurs || sectionCessionnaires || sectionFondations || sectionConjoints || sectionFiduciaires || sectionRentiers)
      {
          contientPartiesLiees = true;
      }

      const platform = require('platform');

      const ua = navigator.userAgent;
      const browser = platform.parse(ua);
      const name = browser.name;
      const version = parseFloat(browser.version);

      var cssInfosGenerales = "";
      var cssInfosDetention = "";
      var cssPartiesLiees = "";
      if (contientPartiesLiees)
      {
          cssInfosGenerales = "col-xs-24 col-sm-12 col-lg-8 stretch";
          cssInfosDetention = "col-xs-24 col-sm-12 col-lg-8 stretch";
          cssPartiesLiees = "col-xs-24 col-sm-24 col-lg-8 stretch";
          if (! (name === 'IE' && version === 11))
          {
              cssInfosGenerales +=  " flexInfosGenerales50 flexPanelsLarge33";
              cssInfosDetention +=  " flexInfosDetention50 flexPanelsLarge33";
              cssPartiesLiees += " flexPanelsLarge33";
          }
      }
      else
      {
          cssInfosGenerales = "col-xs-24 col-sm-12 stretch";
          cssInfosDetention = "col-xs-24 col-sm-12 stretch";
          if (! (name === 'IE' && version === 11))
          {
              cssInfosGenerales += " flexInfosGenerales50 flexPanelsLarge50";
              cssInfosDetention += " flexInfosDetention50 flexPanelsLarge50";
          }
      }

      var sectionPartiesLiees = "";
      if (contientPartiesLiees)
      {
        sectionPartiesLiees = <div className={cssPartiesLiees}>
                                <div className="panel panel-default sansMarginBottom">
                                <div className="panel-body">
                                    <h3 id="titrePartiesLiees">{I18nConfig.obtenirRessource("sectionInfosPolice:TitrePartiesLiees")}</h3>
                                    <ul className="unstyled ulPanels">
                                    {sectionCoPreneurs}
                                    {sectionCessionnaires}
                                    {sectionFondations}
                                    {sectionRentiers}
                                    {sectionConjoints}
                                    {sectionFiduciaires}
                                    </ul>
                                </div>
                                </div>
                                </div>
      }

      return (
        <React.Fragment>
        <div className={cssInfosGenerales}>
        <div className="panel panel-default sansMarginBottomEnLarge">
         <div className="panel-body">
           <h3 id="titreInfosGenerales">{I18nConfig.obtenirRessource("sectionInfosPolice:TitreInformationsGenerales")}</h3>
           <ul className="unstyled ulPanels">
             <li className="ligneInfo">
                 <label id="lblNomProduit" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:NomDuProduit")}</b></label>
                 <span id="txtNomProduit"> <ValeurAvecAucunCache valeur={this.props.nomProduit} /></span>
             </li>
             <li className="ligneInfo">
                 <label id="lblDateContrat" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:DateContrat")}</b></label>
                 <span id="txtDateContrat"> <ValeurAvecAucunCache valeur={this.props.dateContrat} /></span>
             </li>
             <li className="ligneInfo">
                 <label id="lblStatut" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Statut")}</b></label>
                 <span id="txtStatut"> <ValeurAvecAucunCache valeur={this.props.statut} /></span>
            </li>
             <li className="ligneInfo">
                 <label id="lblPreneur" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Preneur")}</b></label>
                 <span id="txtPreneur"> <ValeurAvecAucunCache valeur={this.props.preneur} /></span>
             </li>
             <li className="ligneInfo">
                 <label id="lblNoClient" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:NoDeClient")}</b></label>
                 <span id="txtNoClient"> <ValeurAvecAucunCache valeur={this.props.noClient} /></span>
             </li>
             <li className="ligneInfo">
                 <label id="lblRepresentant" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Representant")}</b></label>
                 <span id="txtRepresentant"> <ValeurAvecAucunCache valeur={this.props.representant} /></span>
             </li>
          </ul>
          </div>
          </div>
         </div>
         <div className={cssInfosDetention}>
         <div className="panel panel-default sansMarginBottomEnLarge">
         <div className="panel-body">
             <h3 id="titreSommaireDetention">{I18nConfig.obtenirRessource("sectionInfosPolice:TitreInformationsDetention")}</h3>
             <ul className="unstyled ulPanels">
                 <li className="ligneInfo">
                     <label id="lblTypeRente" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:TypeRente")}</b></label>
                     <span id="txtTypeRente"> <ValeurAvecAucunCache valeur={this.props.typeRente} /></span>
                 </li>
                 <li className="ligneInfo">
                     <label id="lblTypeRegime" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:TypeRegime")}</b></label>
                     <span id="txtTypeRegime"> <ValeurAvecAucunCache valeur={this.props.typeRegime} /></span>
                 </li>
                 <li className="ligneInfo">
                     <label id="lblTypeImposition" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:TypeImposition")}</b></label>
                     <span id="txtTypeImposition"> <ValeurAvecAucunCache valeur={this.props.typeImposition} /></span>
                 </li>
                 <li className="ligneInfo">
                     <label id="lblDepotInitial" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:DepotInitial")}</b></label>
                     <span id="txtDepotInitial"> <ValeurAvecAucunCache valeur={this.props.depotInitial} /></span>
                 </li>
                 <li className="ligneInfo">
                     <label id="lblVersementNet" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:VersementNet")}</b></label>
                     <span id="txtVersementNet"> <ValeurAvecAucunCache valeur={this.props.versementNet} /></span>
                 </li>
                 <li className="ligneInfo">
                     <label id="lblProchainVersement" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:ProchainVersement")}</b></label>
                     <span id="txtProchainVersement"> <ValeurAvecAucunCache valeur={this.props.prochainVersement} /></span>
                 </li>
                 <li className="ligneInfo">
                     <label id="lblFrequence" className="labelLigneInfo"><b>{I18nConfig.obtenirRessource("sectionInfosPolice:Frequence")}</b></label>
                     <span id="txtFrequence"> <ValeurAvecAucunCache valeur={this.props.frequence} /></span>
                 </li>
             </ul>
         </div>
         </div>
         </div>
        {sectionPartiesLiees}
         </React.Fragment>
      );
    }
  }
