import React from "react";
import InfosPoliceRenteServie from "./infosPoliceRenteServie";
import InfosPoliceProduitAccumulation from "./infosPoliceProduitAccumulation";
import InfosPoliceProduitDecaissement from "./infosPoliceProduitDecaissement";
import PeriodeGarantie from "./periodeGarantie";
import DetailsCredirentiers from "./detailsCredirentiers";
import DetailsRenteReversible from "./detailsRenteReversible";
import DonneesGeneralesEncaissement from "./donneesGeneralesEncaissement";
import FondsInteretGaranti from "./fondsInteretGaranti";
import FondsInteretQuotidien from "./fondsInteretQuotidien";
import FondsTermeLieMarche from "./fondsTermeLieAuMarche";
import ValeurDateLongue from "./valeurDateLongue";
import Configuration from "./Configuration";
import I18nConfig from "./I18n";
import axios from "axios";
import Authentification from "./Authentification";
import Footer from "./footer";
import Header from "./header";
import { Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import DetailsBeneficiaires from "./detailsBeneficiaire";

export default class DetailContrat extends React.Component {

  constructor(props) {
    super(props);
    const { cookies } = props;

    this.auth = new Authentification();
    this.auth.setCookies(cookies);

    const donneesVides = {
      noContrat: "",
      nomProduit: "",
      dateContrat: "",
      preneur: "",
      noClient: "",
      representant: "",
      statutPrestige: "",
      statut: "",
      typeRente: "",
      codeTypeRente: "",
      typeRegime: "",
      typeImposition: "",
      depotInitial: "",
      valeurAccumulee: "",
      versementNet: "",
      frequence: "",
      coPreneurs: [],
      cessionnaires: [],
      fondations: [],
      conjoints: [],
      fiduclaires: [],
      rentier: "",
      montantCpa: "",
      payeurs: [],
      prochainPrelevement: "",
      modePaiement: "",
      informations: "",
      informationsBancaires: "",
      typePolice: "",
      an: "",
      mois: "",
      age: "",
      prochainVersement: "",
      creditsRentiers: [],
      typeRenteReversible: "",
      prestationRenteReversible: "",
      versementCumulatif: "",
      minimumLegal: "",
      maximumViager: "",
      portionImposable: "",
      listeFondsInteretQuotidien: [],
      listeFondsInteretGaranti: [],
      listeFondsTermeLieMarche: [],
      typeCarteCredit: "",
      noCarteCredit: "",
      dateExpCarteCredit: "",
      listeBeneficiaires: [],
    };

    this.state = {
      dataPolice: donneesVides,
      donneesRecues: false,
      langue: "",
      urlWebi: "",
    };
  }

  getLanguage() {
    var listeNomPossibleFr = Configuration.obtenirConfigurations().LISTE_URL_FR;
    var currentUrl = window.location.origin;
    return listeNomPossibleFr.indexOf(currentUrl) > -1 ? "fr" : "en";
  }

  getQueryStrings() {
    var url = window.location.search;
    var queryString = url.substring(url.indexOf("?") + 1);
    var params = {},
      queries,
      temp,
      i,
      l;

    queries = queryString.split("&");

    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split("=");
      params[temp[0]] = temp[1];
    }
    return params;
  }

  async componentDidMount() {
    this.auth.initManager(window.location);

    if (this.estRetourCallBack()) {
      this.auth.authorizePiseExt();
    } else if (this.estCallBack("pise")) {
      this.auth.signInCallback();
    } else if (!this.auth.isAuthenticated()) {
      this.auth.logIn();
    } else {
      var params = this.getQueryStrings();

      const systeme = params.systeme;
      const noPolice = params.noPolice;
      const codeLangue = this.getLanguage();

      this.setState({ langue: codeLangue });
      this.getDataPolice(systeme, noPolice, codeLangue);
      this.logguerCybermetrie();
    }
  }

  logguerCybermetrie() {
    window.dataLayer.push({
      event: "interractionsPages",
      category: "Action",
      action: "Clic",
      label: "Détail contrat épargne",
      value: 0,
      nonInteraction: false,
    });
  }

  estCallBack(codeCallBack) {
    var params = this.getQueryStrings();
    const callback = params.callback;
    return callback === codeCallBack;
  }

  estRetourCallBack() {
    if (window.location.hash == "") {
      return false;
    }

    var dict = new Object();
    var keyValues = window.location.hash.split("&"); //[1].split('=')[1];
    keyValues.forEach((element) => {
      var keyValue = element.split("=");
      var key = keyValue[0];
      var value = keyValue[1];
      dict[key] = value;
    });
    var url = new URL(window.location.href);
    const scope = dict["scope"];
    return scope === "openid";
  }

  getApiUrl(systeme, noPolice) {
    return (
      Configuration.obtenirConfigurations().URL_BFF +
      "/v1/" +
      systeme +
      "/" +
      noPolice
    );
  }

  getWebiUrl(systeme, noPolice, codeLangue) {
    const lang = codeLangue.toUpperCase() === "FR" ? "Francais" : "English";
    return (
      Configuration.obtenirConfigurations().URL_WEBI +
      lang +
      "/DemnService.aspx?NoContrat=" +
      noPolice +
      "&Compagnie=" +
      systeme +
      "&Dest=R&Sujet=3"
    );
  }

  async getDataPolice(systeme, noPolice, codeLangue) {
    const urlWebi = this.getWebiUrl(systeme, noPolice, codeLangue);
    const apiUrl = this.getApiUrl(systeme, noPolice);

    axios
      .get(apiUrl + "?timestamp=" + new Date().getTime(), {
        headers: {
          Authorization: "Bearer " +  sessionStorage.getItem("accessToken"),
          "Accept-Language": codeLangue,
        },
      })
      .then((res) => {
        this.mapResultatDataPolice(res.data, urlWebi);
      })
      .catch((err) => {
        if (!err.response || !err.response.status)
          window.location = "/pagesErreur/500.html";
        else if (err.response.status === 401 || err.response.status === 403)
          window.location = "/pagesErreur/401.html";
        else if (err.response.status === 404)
          window.location = "/pagesErreur/404.html";
        else if (err.response.status === 500 || err.response.status === 503)
          window.location = "/pagesErreur/500.html";
      });
  }

  mapResultatDataPolice(dataPoliceResultat, urlWebi) {
    var donneesPolice = this.transformerResultatEnDataPolice(
      dataPoliceResultat,
      urlWebi
    );

    this.setState({ dataPolice: donneesPolice, donneesRecues: true });
  }

  getPartieTitreTypeDetentionSelonTypePolice(typePolice) {
    var partieTitre = "";

    if (typePolice === "renteServie") {
      partieTitre = I18nConfig.obtenirRessource(
        "commun:PartieTitreRenteServie"
      );
    } else if (typePolice === "renteAccumulation") {
      partieTitre = I18nConfig.obtenirRessource(
        "commun:PartieTitreProduitAccumulation"
      );
    } else if (typePolice === "ferrFrv") {
      partieTitre = I18nConfig.obtenirRessource(
        "commun:PartieTitreProduitDecaissement"
      );
    }

    return partieTitre;
  }

  transformerResultatEnDataPolice(dataPoliceResultat, urlWebia) {
    var donneesPolice = null;

    if (dataPoliceResultat.typeContrat === "renteServie") {
      donneesPolice = {
        typePolice: dataPoliceResultat.typeContrat,
        noContrat: dataPoliceResultat.contrat.noContrat,
        nomProduit: dataPoliceResultat.contrat.nomContrat,
        dateContrat: dataPoliceResultat.contrat.dateEtablissement,
        preneur: dataPoliceResultat.contrat.preneur.nomComplet,
        noClient: dataPoliceResultat.contrat.preneur.noClient,
        representant: dataPoliceResultat.contrat.representant.nomComplet,
        statutPrestige: dataPoliceResultat.contrat.dossierPrestige,
        statut: dataPoliceResultat.contrat.statut,
        codeTypeRente: dataPoliceResultat.contrat.codeTypeRente,
        typeRente: dataPoliceResultat.contrat.typeRente,
        typeRegime: dataPoliceResultat.contrat.typeRegime,
        typeImposition: dataPoliceResultat.contrat.typeImpot,
        depotInitial: dataPoliceResultat.contrat.depotInitial,
        versementNet: dataPoliceResultat.contrat.versementNet,
        frequence: dataPoliceResultat.contrat.frequencePaiement,
        coPreneurs: dataPoliceResultat.contrat.copreneurs,
        cessionnaires: dataPoliceResultat.contrat.cessionnaires,
        fondations: dataPoliceResultat.contrat.fondations,
        conjoints: dataPoliceResultat.contrat.conjoints,
        fiduciaires: dataPoliceResultat.contrat.fiduciaires,
        rentier: dataPoliceResultat.contrat.rentier,
        an: dataPoliceResultat.contrat.periodeGarantie.an,
        mois: dataPoliceResultat.contrat.periodeGarantie.mois,
        age: dataPoliceResultat.contrat.periodeGarantie.age,
        prochainVersement: dataPoliceResultat.contrat.dateProchainVersement,
        creditsRentiers: dataPoliceResultat.contrat.creditsRentiers,
        typeRenteReversible: dataPoliceResultat.contrat.typeRenteReversible,
        prestationRenteReversible:
          dataPoliceResultat.contrat.prestationRenteReversible,
          listeBeneficiaires: dataPoliceResultat.contrat.listeBeneficiaires,
        urlWebi: urlWebia,
      };
    } else if (dataPoliceResultat.typeContrat === "renteAccumulation") {
      donneesPolice = {
        typePolice: dataPoliceResultat.typeContrat,
        noContrat: dataPoliceResultat.contrat.noContrat,
        nomProduit: dataPoliceResultat.contrat.nomContrat,
        dateContrat: dataPoliceResultat.contrat.dateEtablissement,
        preneur: dataPoliceResultat.contrat.preneur.nomComplet,
        noClient: dataPoliceResultat.contrat.preneur.noClient,
        representant: dataPoliceResultat.contrat.representant.nomComplet,
        statutPrestige: dataPoliceResultat.contrat.dossierPrestige,
        statut: dataPoliceResultat.contrat.statut,
        typeRegime: dataPoliceResultat.contrat.typeRegime,
        valeurAccumulee: dataPoliceResultat.contrat.valeurAccumulee,
        montantCpa: dataPoliceResultat.contrat.montantCPA,
        frequence: dataPoliceResultat.contrat.frequencePaiement,
        prochainPrelevement: dataPoliceResultat.contrat.dateProchainPrelevement,
        modePaiement: dataPoliceResultat.contrat.modePaiement,
        informations: dataPoliceResultat.contrat.infoBancaire,
        typeCarteCredit: dataPoliceResultat.contrat.typeCarteCredit,
        noCarteCredit: dataPoliceResultat.contrat.noCarteCredit,
        dateExpCarteCredit: dataPoliceResultat.contrat.dateExpCarteCredit,
        coPreneurs: dataPoliceResultat.contrat.copreneurs,
        cessionnaires: dataPoliceResultat.contrat.cessionnaires,
        fondations: dataPoliceResultat.contrat.fondations,
        conjoints: dataPoliceResultat.contrat.conjoints,
        fiduciaires: dataPoliceResultat.contrat.fiduciaires,
        payeurs: dataPoliceResultat.contrat.payeurs,
        listeFondsInteretQuotidien:
          dataPoliceResultat.contrat.listeFondsInteretQuotidien,
        listeFondsInteretGaranti:
          dataPoliceResultat.contrat.listeFondsInteretGaranti,
        listeFondsTermeLieMarche:
          dataPoliceResultat.contrat.listeFondsTermeLieMarche,
          listeBeneficiaires: dataPoliceResultat.contrat.listeBeneficiaires,
        urlWebi: urlWebia,
      };
    } else if (dataPoliceResultat.typeContrat === "ferrFrv") {
      donneesPolice = {
        typePolice: dataPoliceResultat.typeContrat,
        noContrat: dataPoliceResultat.contrat.noContrat,
        nomProduit: dataPoliceResultat.contrat.nomContrat,
        dateContrat: dataPoliceResultat.contrat.dateEtablissement,
        preneur: dataPoliceResultat.contrat.preneur.nomComplet,
        noClient: dataPoliceResultat.contrat.preneur.noClient,
        representant: dataPoliceResultat.contrat.representant.nomComplet,
        statutPrestige: dataPoliceResultat.contrat.dossierPrestige,
        statut: dataPoliceResultat.contrat.statut,
        typeRegime: dataPoliceResultat.contrat.typeRegime,
        typeImposition: dataPoliceResultat.contrat.typeImpot,
        valeurAccumulee: dataPoliceResultat.contrat.valeurAccumulee,
        versementNet: dataPoliceResultat.contrat.versementNet,
        frequence: dataPoliceResultat.contrat.frequencePaiement,
        prochainVersement: dataPoliceResultat.contrat.dateProchainVersement,
        informationsBancaires: dataPoliceResultat.contrat.infoBancaire,
        coPreneurs: dataPoliceResultat.contrat.copreneurs,
        cessionnaires: dataPoliceResultat.contrat.cessionnaires,
        fondations: dataPoliceResultat.contrat.fondations,
        conjoints: dataPoliceResultat.contrat.conjoints,
        fiduciaires: dataPoliceResultat.contrat.fiduciaires,
        payeurs: dataPoliceResultat.contrat.payeurs,
        versementCumulatif: dataPoliceResultat.contrat.versementCumulatif,
        minimumLegal: dataPoliceResultat.contrat.minimumLegal,
        maximumViager: dataPoliceResultat.contrat.maximumViager,
        portionImposable: dataPoliceResultat.contrat.portionImposable,
        typeRenteReversible: dataPoliceResultat.contrat.typeRenteReversible,
        prestationRenteReversible:
          dataPoliceResultat.contrat.prestationRenteReversible,
        listeFondsInteretQuotidien:
          dataPoliceResultat.contrat.listeFondsInteretQuotidien,
        listeFondsInteretGaranti:
          dataPoliceResultat.contrat.listeFondsInteretGaranti,
        listeFondsTermeLieMarche:
          dataPoliceResultat.contrat.listeFondsTermeLieMarche,
          listeBeneficiaires: dataPoliceResultat.contrat.listeBeneficiaires,
        urlWebi: urlWebia,
      };
    }

    return donneesPolice;
  }

  render() {
    var sectionLoader;
    if (!this.state.donneesRecues) {
      sectionLoader = (
        <div className="loaderWrapper">
          <div className="chargement" tabIndex="-1">
            <span className="sr-only">Téléchargement en cours.</span>
          </div>
        </div>
      );
    }

    var sectionInfosPolice;

    if (this.state.dataPolice?.typePolice === "renteServie") {
      sectionInfosPolice = (
        <div className="row equal infosPolice" id="infosPoliceRentes">
          <InfosPoliceRenteServie
            noContrat={this.state.dataPolice.noContrat}
            nomProduit={this.state.dataPolice.nomProduit}
            dateContrat={this.state.dataPolice.dateContrat}
            preneur={this.state.dataPolice.preneur}
            noClient={this.state.dataPolice.noClient}
            representant={this.state.dataPolice.representant}
            statut={this.state.dataPolice.statut}
            typeRente={this.state.dataPolice.typeRente}
            typeRegime={this.state.dataPolice.typeRegime}
            typeImposition={this.state.dataPolice.typeImposition}
            depotInitial={this.state.dataPolice.depotInitial}
            versementNet={this.state.dataPolice.versementNet}
            prochainVersement={this.state.dataPolice.prochainVersement}
            frequence={this.state.dataPolice.frequence}
            urlWebi={this.state.dataPolice.urlWebi}
            coPreneurs={this.state.dataPolice.coPreneurs}
            cessionnaires={this.state.dataPolice.cessionnaires}
            fondations={this.state.dataPolice.fondations}
            conjoints={this.state.dataPolice.conjoints}
            fiduciaires={this.state.dataPolice.fiduciaires}
            rentier={this.state.dataPolice.rentier}
          />
        </div>
      );
    }
    if (this.state.dataPolice?.typePolice === "renteAccumulation") {
      sectionInfosPolice = (
        <div
          className="row equal infosPolice"
          id="infosPoliceProduitAccumulation"
        >
          <InfosPoliceProduitAccumulation
            noContrat={this.state.dataPolice.noContrat}
            nomProduit={this.state.dataPolice.nomProduit}
            dateContrat={this.state.dataPolice.dateContrat}
            preneur={this.state.dataPolice.preneur}
            noClient={this.state.dataPolice.noClient}
            representant={this.state.dataPolice.representant}
            statut={this.state.dataPolice.statut}
            typeRegime={this.state.dataPolice.typeRegime}
            valeurAccumulee={this.state.dataPolice.valeurAccumulee}
            montantCpa={this.state.dataPolice.montantCpa}
            urlWebi={this.state.dataPolice.urlWebi}
            frequence={this.state.dataPolice.frequence}
            prochainPrelevement={this.state.dataPolice.prochainPrelevement}
            modePaiement={this.state.dataPolice.modePaiement}
            informations={this.state.dataPolice.informations}
            typeCarteCredit={this.state.dataPolice.typeCarteCredit}
            noCarteCredit={this.state.dataPolice.noCarteCredit}
            dateExpCarteCredit={this.state.dataPolice.dateExpCarteCredit}
            coPreneurs={this.state.dataPolice.coPreneurs}
            cessionnaires={this.state.dataPolice.cessionnaires}
            fondations={this.state.dataPolice.fondations}
            conjoints={this.state.dataPolice.conjoints}
            fiduciaires={this.state.dataPolice.fiduciaires}
            payeurs={this.state.dataPolice.payeurs}
          />
        </div>
      );
    }
    if (this.state.dataPolice?.typePolice === "ferrFrv") {
      sectionInfosPolice = (
        <div
          className="row equal infosPolice"
          id="infosPoliceProduitDecaissement"
        >
          <InfosPoliceProduitDecaissement
            noContrat={this.state.dataPolice.noContrat}
            nomProduit={this.state.dataPolice.nomProduit}
            dateContrat={this.state.dataPolice.dateContrat}
            preneur={this.state.dataPolice.preneur}
            noClient={this.state.dataPolice.noClient}
            representant={this.state.dataPolice.representant}
            statut={this.state.dataPolice.statut}
            typeRegime={this.state.dataPolice.typeRegime}
            typeImposition={this.state.dataPolice.typeImposition}
            valeurAccumulee={this.state.dataPolice.valeurAccumulee}
            versementNet={this.state.dataPolice.versementNet}
            prochainVersement={this.state.dataPolice.prochainVersement}
            frequence={this.state.dataPolice.frequence}
            informationsBancaires={this.state.dataPolice.informationsBancaires}
            urlWebi={this.state.dataPolice.urlWebi}
            coPreneurs={this.state.dataPolice.coPreneurs}
            cessionnaires={this.state.dataPolice.cessionnaires}
            fondations={this.state.dataPolice.fondations}
            conjoints={this.state.dataPolice.conjoints}
            fiduciaires={this.state.dataPolice.fiduciaires}
            payeurs={this.state.dataPolice.payeurs}
          />
        </div>
      );
    }

    var sectionEntete = "";
    var dateJour = Date.now();
    if (this.state.donneesRecues) {
      sectionEntete = (
        <div className="row">
          <div id="sectionEntete" className="sectionEntete col-xs-24">
            <span id="txtDateJour">
              <ValeurDateLongue
                dateAfficher={dateJour}
                langue={this.state.langue}
              />
            </span>
            <h1 id="titreDetailDetentions" className="titreDetailDetentions">
              <span id="titreDetailDetentionPartieGenerique">
                {I18nConfig.obtenirRessource("commun:TitreDetailDetentions")}
              </span>{" "}
              <span id="txtNoContratEntete">
                {this.state.dataPolice?.noContrat}
              </span>
            </h1>
            <h2 className="titreTypeProduit">
              <span id="txtTypeProduit">
                {" "}
                {this.getPartieTitreTypeDetentionSelonTypePolice(
                  this.state.dataPolice?.typePolice
                )}
              </span>
            </h2>
          </div>
        </div>
      );
    }

    var sectionPeriodeGarantie = "";
    if (this.state.dataPolice?.typePolice === "renteServie") {
      sectionPeriodeGarantie = (
        <div className="row">
          <div
            id="sectionPeriodeGarantie"
            className="sectionMarginTop col-xs-24 col-sm-12"
          >
            <PeriodeGarantie
              an={this.state.dataPolice.an}
              mois={this.state.dataPolice.mois}
              age={this.state.dataPolice.age}
            />
          </div>
        </div>
      );
    }

    var sectionDetailsCredirentiers = "";
    if (this.state.dataPolice?.typePolice === "renteServie") {
      sectionDetailsCredirentiers = (
        <div className="row">
          <div
            id="sectionDetailsCredirentiers"
            className="sectionMarginTop col-xs-24"
          >
            <DetailsCredirentiers
              creditsRentiers={this.state.dataPolice.creditsRentiers}
              urlWebi={this.state.dataPolice.urlWebi}
              rentier={this.state.dataPolice.rentier}
            />
          </div>
        </div>
      );
    }

    var sectionDetailsRenteReversible = "";
    if (
      this.state.dataPolice?.typePolice === "renteServie" &&
      this.state.dataPolice.codeTypeRente === "J"
    ) {
      sectionDetailsRenteReversible = (
        <div className="row">
          <div
            id="sectionDetailsRenteReversible"
            className="sectionMarginTop col-xs-24 col-sm-12"
          >
            <DetailsRenteReversible
              typeRenteReversible={this.state.dataPolice.typeRenteReversible}
              prestationRenteReversible={
                this.state.dataPolice.prestationRenteReversible
              }
            />
          </div>
        </div>
      );
    }
    var sectionDonneesGeneralesEncaissement = "";
    if (this.state.dataPolice?.typePolice === "ferrFrv") {
      sectionDonneesGeneralesEncaissement = (
        <div className="row">
          <div
            id="sectionDonneesGeneralesEncaissement"
            className="col-xs-24 col-md-16 sectionMarginTop"
          >
            <DonneesGeneralesEncaissement
              versementCumulatif={this.state.dataPolice.versementCumulatif}
              minimumLegal={this.state.dataPolice.minimumLegal}
              maximumViager={this.state.dataPolice.maximumViager}
              portionImposable={this.state.dataPolice.portionImposable}
            />
          </div>
        </div>
      );
    }

    var sectionfondsInteretQuotidien = "";
    if (
      (this.state.dataPolice?.typePolice === "renteAccumulation" ||
        this.state.dataPolice?.typePolice === "ferrFrv") &&
      this.state.dataPolice.listeFondsInteretQuotidien
    ) {
      sectionfondsInteretQuotidien = (
        <div className="row">
          <div
            id="sectionFondsInteretQuotidien"
            className="sectionMarginTop col-xs-24 col-md-13"
          >
            <FondsInteretQuotidien
              listeFondsInteretQuotidien={
                this.state.dataPolice.listeFondsInteretQuotidien
              }
            />
          </div>
        </div>
      );
    }

    var sectionFondsInteretGaranti = "";
    if (
      (this.state.dataPolice?.typePolice === "renteAccumulation" ||
        this.state.dataPolice?.typePolice === "ferrFrv") &&
      this.state.dataPolice.listeFondsInteretGaranti
    ) {
      sectionFondsInteretGaranti = (
        <div className="row">
          <div id="sectionFondsInteretGaranti" className="col-xs-24">
            <FondsInteretGaranti
              listeFondsInteretGaranti={
                this.state.dataPolice.listeFondsInteretGaranti
              }
            />
          </div>
        </div>
      );
    }

    var sectionFondsTermeLieAuMarche = "";
    if (
      (this.state.dataPolice?.typePolice === "renteAccumulation" ||
        this.state.dataPolice?.typePolice === "ferrFrv") &&
      this.state.dataPolice?.listeFondsTermeLieMarche
    ) {
      sectionFondsTermeLieAuMarche = (
        <div className="row">
          <div id="sectionFondsTermeLieAuMarche" className="col-xs-24">
            <FondsTermeLieMarche
              listeFondsTermeLieMarche={
                this.state.dataPolice.listeFondsTermeLieMarche
              }
            />
          </div>
        </div>
      );
    }

    var sectionDetailsBeneficiaires = "";
    if (this.state.donneesRecues) {
    sectionDetailsBeneficiaires = (
        <div className="row">
          <div
            id="sectionDetailsBeneficiaires"
            className="sectionMarginTop col-xs-24"
          >
            <DetailsBeneficiaires
              listeBeneficiaires={
                this.state.dataPolice?.listeBeneficiaires
              }
            />
          </div>
        </div>
      );
  }

    var sectionHeader = "";
    if (this.state.donneesRecues) {
      sectionHeader = <Header />;
    }

    var sectionMain = "";
    if (this.state.dataPolice?.typePolice === "renteServie") {
      sectionMain = (
        <div className="container-fluid">
          {sectionLoader}
          {sectionEntete}
          {sectionInfosPolice}
          {sectionPeriodeGarantie}
          {sectionDetailsCredirentiers}
          {sectionDetailsRenteReversible}
          {sectionDonneesGeneralesEncaissement}
          {sectionfondsInteretQuotidien}
          {sectionFondsInteretGaranti}
          {sectionFondsTermeLieAuMarche}
          {sectionDetailsBeneficiaires}   
        </div>
      )
    } else {
      sectionMain = (
        <div className="container-fluid">
          {sectionLoader}
          {sectionEntete}
          {sectionInfosPolice}  
          {sectionPeriodeGarantie}      
          {sectionDetailsCredirentiers}
          {sectionDetailsRenteReversible}
          {sectionDonneesGeneralesEncaissement}
          {sectionDetailsBeneficiaires} 
          {sectionfondsInteretQuotidien}
          {sectionFondsInteretGaranti}
          {sectionFondsTermeLieAuMarche}
        </div>
      )
    }

    var sectionFooter = "";
    if (this.state.donneesRecues) {
      sectionFooter = <Footer />;
    }

    if (this.state.donneesRecues) {
      sectionLoader = "";
      document.title =
        I18nConfig.obtenirRessource("commun:TitreDetailDetentions") +
        " " +
        this.state.dataPolice?.noContrat;
    }

    return (
      <div>
        {sectionHeader}
        {sectionMain}
        {sectionFooter}
      </div>
    );
  }
}
