import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import "core-js/features/object/assign";
import "core-js/features/array/find";
import "core-js/features/array/from";
import "core-js/features/string/starts-with";
import "core-js/features/string/includes";
import "core-js/modules/es.promise";
import "core-js/modules/es.symbol";
import "core-js/es/map";
import "core-js/es/set";

import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

const root = createRoot(document.getElementById("root"));
root.render((
  <BrowserRouter>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </BrowserRouter>
));
