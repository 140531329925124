import Oidc from "oidc-client";
import Configuration from "./Configuration";

class Authentification {
  setCookies(cookies) {
    this.cookies = cookies;
  }

  initManager(callBackUrl) {
    const settings = {
      authority: Configuration.obtenirConfigurations().HOST_STS + "/authorize",
      client_id: Configuration.obtenirConfigurations().CLIENTID_STS,
      redirect_uri: callBackUrl,
      response_type: "token",
      extraQueryParams: {
        connection: this.getLanguage() === "fr" ? "gpap" : "gpap",
      },
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      accessTokenExpiringNotificationTime: 100,
    };

    this.mgr = new Oidc.UserManager(settings);
    this.getLanguage = this.getLanguage.bind(this);
  }

  logIn() {
    if(typeof process !== 'undefined' && process.env.JEST_WORKER_ID !== undefined)
      return;
    this.mgr
      .createSigninRequest()
      .then(function (request) {
        window.location = request.url;
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  signInCallback() {
    var hash = window.location.hash.substr(1);
    var result = hash.split("&").reduce(function (result, item) {
      var parts = item.split("=");
      result[parts[0]] = parts[1];
      return result;
    }, {});

    // var expireDate = new Date();
    // var time = expireDate.getTime();
    // var expireTime = result.expires_in * 1000;
    // expireDate.setTime(expireTime);

    // if (this.cookies != undefined) {
    //   this.cookies.set("accessToken", result.access_token, {
    //     path: "/",
    //     //expiry: expireDate.toGMTString(),
    //     maxAge: result.expires_in,
    //   });
    // }

    sessionStorage.setItem("isLoggedIn", "true");
    let expiresAt = result.expires_in * 1000 + new Date().getTime();

    sessionStorage.setItem("accessToken", result.access_token);
    sessionStorage.setItem("expiresAt", expiresAt);

    var urlClean = window.location.href.replace(/#.*$/, "");
    urlClean = urlClean.replace("&callback=pise", "");
    window.location = urlClean;
  }

  isAuthenticated() {
    // debugger;
    // return this.cookies.get("accessToken") != undefined;

    if (!sessionStorage.getItem("isLoggedIn")) return false;

    let expiresAt = sessionStorage.getItem("expiresAt");
    return new Date().getTime() < expiresAt;
  }

  getLanguage() {
    var listeNomPossibleFr = Configuration.obtenirConfigurations().LISTE_URL_FR;
    var currentUrl = window.location.origin;
    return listeNomPossibleFr.indexOf(currentUrl) > -1 ? "fr" : "en";
  }

  authorizePiseExt() {
    var params = this.getQueryStrings();
    var util = require("util");

    var callBackUri = util.format(
      "%s?systeme=%s&noPolice=%s&callback=pise",
      // window.location.href.split('/?')[0],
      window.location.origin,
      params.systeme,
      params.noPolice
    );

    var callBackUriEncode = encodeURIComponent(callBackUri);

    var urlAuthorizePise =
      Configuration.obtenirConfigurations().URL_AUTHORIZE_PISE +
      util.format(
        "?client_id=%s&response_type=token&scope=ies&redirect_uri=%s",
        Configuration.obtenirConfigurations().CLIENTID_AUTHORIZE_PISE,
        callBackUriEncode
      );
    window.location = urlAuthorizePise;
  }

  getQueryStrings() {
    var url = window.location.search;
    var queryString = url.substring(url.indexOf("?") + 1);
    var params = {},
      queries,
      temp,
      i,
      l;

    queries = queryString.split("&");

    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split("=");
      params[temp[0]] = temp[1];
    }
    return params;
  }
}

export default Authentification;
