import React from 'react';
import I18nConfig from "./I18n";
import ValeurAvecAucunCache from './valeurAvecAucunCache';
import * as CacherColonnes from './cacherColonnes.js';

export default class DetailsBeneficiaires extends React.Component {

    componentDidMount () {
        CacherColonnes.cacherColonnesVides("tableauDetailsBeneficiaires", I18nConfig.obtenirRessource('commun:ValeurAucun'));
    };

    render () {
        if (this.props.listeBeneficiaires && this.props.listeBeneficiaires.length > 0) {
            return (
                <div>
                    <h3 id="titreDetailsBeneficiaires" className="sansMarginTop">{I18nConfig.obtenirRessource('detailsBeneficiaires:TitreDetailsBeneficiaires')}</h3>
                    <div className="table-responsive">
                        <table className="table tableau-donnees table-striped" id="tableauDetailsBeneficiaires">
                            <thead>
                                <tr>
                                    <th id="enteteBeneficiaire" className="cell-texte" scope="col">
                                        {I18nConfig.obtenirRessource("detailsBeneficiaires:Nom")}
                                    </th>
                                    <th id="enteteStatut" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("detailsBeneficiaires:Statut")}
                                    </th>
                                    <th id="enteteRepartition" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("detailsBeneficiaires:Repartition")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.listeBeneficiaires.map((itemBeneficiaire, indexBeneficiaire) =>
                                    <tr key={indexBeneficiaire}>
                                        <td id={"tdBeneficiaire_" + indexBeneficiaire} className="cell-texte" headers="enteteBeneficiaire">
                                            <ValeurAvecAucunCache valeur={itemBeneficiaire.nomComplet} />
                                        </td>
                                        <td id={"tdStatut_" + indexBeneficiaire} className="cell-num" headers="enteteStatut">
                                            <ValeurAvecAucunCache valeur={itemBeneficiaire.revocabilite} />
                                        </td>
                                        <td id={"tdRepartition_" + indexBeneficiaire} className="cell-num" headers="enteteRepartition">
                                            <ValeurAvecAucunCache valeur={itemBeneficiaire.pourcentRepartition} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                )
            } else {
                return (
                    <div>
                        <h3 id="titreDetailBeneficiaires">{I18nConfig.obtenirRessource('detailsBeneficiaires:TitreDetailsBeneficiaires')}</h3>
                        <div id="sectionMessageAvertissementAucunBeneficiaire" className="alert alert-warning">
                            <b><span id="titreMessageAvertissementAucunBeneficiaire">{I18nConfig.obtenirRessource('detailsBeneficiaires:TitreMessageAvertissement')} </span></b>
                            <span id="messageAucunBeneficiaire">{I18nConfig.obtenirRessource('detailsBeneficiaires:AucunBenificiaire')}</span>
                        </div>
                    </div>
                );
            }
    }
  }
