import React from 'react';
import I18nConfig from "./I18n";
import ValeurAvecAucunCache from './valeurAvecAucunCache';

export default class FondsTermeLieAuMarche extends React.Component {

    placementsTermeLieAuMarche(props)
    {
        return (
            <div className="sectionMarginTop">
                <h3 id={"titreFondTermeLieAuMarche_" + props.indexFond} className="sansMarginTop">{props.listePlacementTermeLieMarche[0].titre}</h3>
                <div className="table-responsive">
                    <table className="table tableau-donnees table-striped" id={"tableauPlacementsTermeLieAuMarche_" + props.indexFond}>
                        <thead>
                            <tr>
                                <th id={"enteteNomProduit_" + props.indexFond} className="cell-texte colonneNomProduit" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:NomProduit")}
                                </th>
                                <th id={"enteteDateInitiale_" + props.indexFond} className="cell-texte colonneDate" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:DateInitiale")}
                                </th>
                                <th id={"enteteMontant_" + props.indexFond} className="cell-num" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:Montant")}
                                </th>
                                <th id={"enteteRevenuBoursier_" + props.indexFond} className="cell-num" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:RevenuBoursier")}
                                </th>
                                <th id={"enteteTermeLieMarcheDuree_" + props.indexFond} className="cell-texte oddTitle" scope="col" colSpan="2">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:Duree")}
                                </th>
                                <th id={"enteteTermeLieMarcheDateEcheance_" + props.indexFond} className="cell-texte colonneDate" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:DateEcheance")}
                                </th>
                                <th id={"enteteValeurGarantieEcheance_" + props.indexFond} className="cell-texte" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:ValeurGarantieEcheance")}
                                </th>
                                <th id={"enteteTermeLieMarcheValeurAccumulee_" + props.indexFond} className="cell-num" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:ValeurAccumulee")}
                                </th>
                            </tr>
                            <tr>
                                <th id={"enteteTermeLieMarcheMois_" + props.indexFond} className="cell-num" scope="col">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:Mois")}
                                </th>
                                <th id={"enteteTermeLieMarcheJours_" + props.indexFond} className="cell-num" scope="col">
                                    {I18nConfig.obtenirRessource("placementTermeLieAuMarche:Jour")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.listePlacementTermeLieMarche.map((itemPlacementTermeLieMarche, indexPlacementTermeLieMarche) =>
                                <tr key={indexPlacementTermeLieMarche}>
                                    <td id={"tdNomProduit_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-texte colonneNomProduit" headers={"enteteNomProduit_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.nomProduit} />
                                    </td>
                                    <td id={"tdDateInitiale_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-texte colonneDate" headers={"enteteDateInitiale_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.dateInitiale} />
                                    </td>
                                    <td id={"tdMontant_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-num" headers={"enteteMontant_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.montant} />
                                    </td>
                                    <td id={"tdRevenuBoursier_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-num" headers={"enteteRevenuBoursier_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.revenuBoursier} />
                                    </td>
                                     {/* eslint-disable */}
                                    <td id={"tdTermeLieMarcheDureeMois_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-num"
                                        headers={"enteteTermeLieMarcheDuree_" + props.indexFond + " " + "enteteTermeLieMarcheMois_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.dureeMois} />
                                    </td>
                                    <td id={"tdTermeLieMarcheDureeJours_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-num"
                                        headers={"enteteTermeLieMarcheDuree_" + props.indexFond + " " + "enteteTermeLieMarcheJours_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.dureeJour} />
                                    </td>
                                     {/* eslint-enable */}
                                    <td id={"tdTermeLieMarcheDateEcheance_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-texte colonneDate" headers={"enteteTermeLieMarcheDateEcheance_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.dateEcheance} />
                                    </td>
                                    <td id={"tdValeurGarantieEcheance_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-num" headers={"enteteValeurGarantieEcheance_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.valeurGarantieEcheance} />
                                    </td>
                                    <td id={"tdTermeLieMarcheValeurAccumulee_" + props.indexFond + "_" + indexPlacementTermeLieMarche}
                                        className="cell-num" headers={"enteteTermeLieMarcheValeurAccumulee_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementTermeLieMarche.valeurAccumulee} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th id={"enteteTotal" + props.indexFond} scope="row">{I18nConfig.obtenirRessource("commun:Total")}</th>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td id={"tdTermeLieMarcheValeurAccumuleeTotale_" + props.indexFond} className="cell-num">{props.valeurAccumuleeTotale}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            )
    }

    render () {
        return (this.props.listeFondsTermeLieMarche.map((itemFondTermeLieMarche, indexFondTermeLieMarche) =>
                    <div key={indexFondTermeLieMarche}>
                        <this.placementsTermeLieAuMarche typePlacement={itemFondTermeLieMarche.typePlacement}
                                                       valeurAccumuleeTotale={itemFondTermeLieMarche.valeurAccumuleeTotale}
                                                       listePlacementTermeLieMarche={itemFondTermeLieMarche.listePlacementTermeLieMarche}
                                                       indexFond={indexFondTermeLieMarche} />
                    </div>
                ))

    }
  }
