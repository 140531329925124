import React from 'react';
import I18nConfig from "./I18n";
import ValeurAvecAucunCache from './valeurAvecAucunCache';

export default class FondsInteretQuotidien extends React.Component {

    render () {

            return (
                <div>
                    <h3 id="titreFondsInteretQuotidien" className="sansMarginTop">{I18nConfig.obtenirRessource("fondInteretQuotidien:TitrePlacementInteretQuotidien")}</h3>
                    <div className="table-responsive">
                        <table className="table tableau-donnees table-striped" id="tableauFondsInteretQuotidien">
                            <thead>
                                <tr>
                                    <th id="enteteFondInteretQuotidienNomProduit" className="cell-texte colonneNomProduit" scope="col">
                                        {I18nConfig.obtenirRessource("fondInteretQuotidien:NomProduit")}
                                    </th>
                                    <th id="enteteFondInteretQuotidienTauxInteret" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("fondInteretQuotidien:TauxInteret")}
                                    </th>
                                    <th id="enteteFondInteretQuotidienValeurAccumulee" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("fondInteretQuotidien:ValeurAccumulee")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.listeFondsInteretQuotidien.map((itemFondInteretQuotidien, indexFondInteretQuotidien) =>
                                    <tr key={indexFondInteretQuotidien}>
                                        <td id={"tdFondInteretQuotidienNomProduit_" + indexFondInteretQuotidien} className="cell-texte colonneNomProduit"
                                            headers="enteteFondInteretQuotidienNomProduit">
                                            <ValeurAvecAucunCache valeur={itemFondInteretQuotidien.nomProduit} />
                                        </td>
                                        <td id={"tdFondInteretQuotidienTauxInteret_" + indexFondInteretQuotidien} className="cell-num"
                                            headers="enteteFondInteretQuotidienTauxInteret">
                                            <ValeurAvecAucunCache valeur={itemFondInteretQuotidien.tauxInteret} />
                                        </td>
                                        <td id={"tdFondInteretQuotidienValeurAccumulee_" + indexFondInteretQuotidien} className="cell-num"
                                            headers="enteteFondInteretQuotidienValeurAccumulee">
                                            <ValeurAvecAucunCache valeur={itemFondInteretQuotidien.valeurAccumuleeTotale} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                )

    }
  }
