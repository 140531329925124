import React, { Component } from "react";
import I18n from "./I18n";

export default class extends Component {

    render() {
        return <div id="container" className="container containerLogo">
                    <header>
                        <div id="zoneEntetePage">
                            <div id="zoneEntete" className="zone-entete">
                                <div id="zoneLogo" className="col-xs-12 zone-logo">
                                    <a id="urlLogo" target="_blank" rel="noopener noreferrer" href={I18n.t('header:urlLogo')} title={I18n.t('header:dsfLogo')}>
                                        <img className="img-responsive hidden-xs hidden-sm" id="logoDsf"
                                             alt={I18n.t('header:dsfLogo')} src={I18n.t('header:dsfCheminImage')} />
                                        <img className="img-responsive hidden-md hidden-lg" id="logoDsf"
                                             alt={I18n.t('header:dsfLogo')} src={I18n.t('header:dsfCheminImageMobile')} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>;
    }
}
