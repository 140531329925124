
    export function cacherColonnesVides(nomTable, valeurAucunCache)
    {
        var table = document.getElementById(nomTable);

        //Si la table n'existe pas ou n'est pas loadée, on fait rien
        if (!table)
            return;

        var body = table.getElementsByTagName("tbody");
        var rows = body[0].childNodes;

        //Si la table ne contient aucune row, on fait rien
        if (!rows || rows.length === 0)
            return;

        var header = table.getElementsByTagName("thead");
        var entetesColonneToutes = header[0].getElementsByTagName("th");        
        var entetesColonne = [];
        
        //Rassembler entêtes colonne standard et non des regroupements d'entêtes
        for(var i = 0; i < entetesColonneToutes.length; i++){ 
            if (entetesColonneToutes[i].colSpan < 2) {
                entetesColonne.push(entetesColonneToutes[i]);
            }
        }

        //Rassembler rows standard et les rows sous-menu
        var rowsStandard = [];
        var rowsSousMenu = [];        

        for(var y = 0; y < rows.length; y++){ 
            if (rows[y].childNodes[0].colSpan < 2) {
                rowsStandard.push(rows[y]);
            }
            else {
                rowsSousMenu.push(rows[y]);
            }
        }

        //Trouver indices colonnes à enlever (cacher)
        var indicesColonnesAEnlever = [];

        for(var z = 0; z < rowsStandard[0].childNodes.length; z++){ 

            var auMoinsUneDonnee = false;

            for(var k = 0; k < rowsStandard.length; k++){
                if (rowsStandard[k].childNodes[z] && rowsStandard[k].childNodes[z].innerText !== valeurAucunCache) {
                    auMoinsUneDonnee = true;
                }
            }

            if (!auMoinsUneDonnee)
            {
                indicesColonnesAEnlever.push(z);
            }
        }

        //Mettre une classe displayNone sur colonnes à enlever (entête et cellule).  
        //Enlever tout displaynone précédent pour les autres colonnes s'il y a lieu
        for(var l = 0; l < entetesColonne.length; l++){

            if (estIndiceColonneAEnlever(indicesColonnesAEnlever, l))
            {
                entetesColonne[l].classList.add("displayNone");
            }
            else
            {
                entetesColonne[l].classList.remove("displayNone");
            }
            for(var m = 0; m < rowsStandard.length; m++){

                if (rowsStandard[m].childNodes[l])
                {
                    if (estIndiceColonneAEnlever(indicesColonnesAEnlever, l))
                    {
                        rowsStandard[m].childNodes[l].classList.add("displayNone");
                    }
                    else
                    {
                        rowsStandard[m].childNodes[l].classList.remove("displayNone");
                    }
                }              
            }
        }

        //Calculer colspan final sous-menu
        var colSpanFinal = entetesColonne.length - indicesColonnesAEnlever.length;

        //Setter colspan sur sous-menus
        if (colSpanFinal > 0) {
            for(var n = 0; n < rowsSousMenu.length; n++){
                var sousMenu = rowsSousMenu[n].getElementsByTagName("th"); 
                var objetModif = document.getElementById(sousMenu[0].id);
                objetModif.colSpan = colSpanFinal;
            }
        }

        //Pour les tables ayant une entête de colonne de toute la largeur du tableau, recalcul du colspan
        if (header[0].childNodes.length > 1 && 
            header[0].childNodes && header[0].childNodes[0].childNodes.length === 1 &&
            header[0].childNodes[0].childNodes[0].colSpan > 1)
        {
            header[0].childNodes[0].childNodes[0].colSpan = getNbColonnesVisibles(entetesColonne);
        }
    }

    function getNbColonnesVisibles(colonnes) {

        var nbColonnesVisibles = 0;

        for(var i = 0; i < colonnes.length; i++){ 
            if (!contientDejaClasse(colonnes[i].classList, "displayNone")) 
            {
                nbColonnesVisibles ++;
            }
        }

        return nbColonnesVisibles;
    }

    function estIndiceColonneAEnlever(indicesColonnesAEnlever, indiceColonne) 
    {
        var estAEnlever = false;

        for(var i = 0; i < indicesColonnesAEnlever.length; i++){ 
            if (indicesColonnesAEnlever[i] === indiceColonne) {
                estAEnlever = true;
            }
        }

        return estAEnlever;
    }

    export function contientDejaClasse(classes, classe)
    {
        var contientClasse = false;

        for(var i = 0; i < classes.length; i++){ 
            if (classes[i] === classe) {
                contientClasse = true;
            }
        }

        return contientClasse;
    }
