import React from 'react';
import I18nConfig from "./I18n";
import ValeurAvecAucunCache from './valeurAvecAucunCache';

export default class PeriodeGarantie extends React.Component {

    render () {

            return (
                <div>
                    <div className="table-responsive">
                        <table className="table tableau-donnees table-striped" id="tableauPeriodeGarantie">
                            <thead>
                                <tr>
                                    <th id="entetePeriodeGarantie" colSpan="3" className="cell-texte oddTitle" scope="col">
                                        {I18nConfig.obtenirRessource("periodeGarantie:PeriodeGarantie")}
                                    </th>
                                </tr>
                                <tr>
                                    <th id="enteteAn" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("periodeGarantie:An")}
                                    </th>
                                    <th id="enteteMois" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("periodeGarantie:Mois")}
                                    </th>
                                    <th id="enteteAge" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("periodeGarantie:Age")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td id="tdAn" className="cell-num" headers="entetePeriodeGarantie enteteAn">
                                        <ValeurAvecAucunCache valeur={this.props.an} />
                                    </td>
                                    <td id="tdMois" className="cell-num" headers="entetePeriodeGarantie enteteMois">
                                        <ValeurAvecAucunCache valeur={this.props.mois} />
                                    </td>
                                    <td id="tdAge" className="cell-num" headers="entetePeriodeGarantie enteteAge">
                                        <ValeurAvecAucunCache valeur={this.props.age} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                )

    }
  }
