import React from 'react';
import I18nConfig from "./I18n";
import ValeurAvecAucunCache from './valeurAvecAucunCache';

export default class DonneesGeneralesEncaissement extends React.Component {

    render () {

            return (
                <div>
                    <div className="table-responsive">
                        <table className="table tableau-donnees table-striped" id="tableauDonneesGeneralesEncaissement">
                            <thead>
                                <tr>
                                    <th id="enteteVersementCumulatif" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("donneesGeneralesEncaissement:VersementCumulatif")}
                                    </th>
                                    <th id="enteteMinimumLegal" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("donneesGeneralesEncaissement:MinimumLegal")}
                                    </th>
                                    <th id="enteteMaximumViager" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("donneesGeneralesEncaissement:MaximumViager")}
                                    </th>
                                    <th id="entetePortionImposable" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("donneesGeneralesEncaissement:PortionImposable")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td id="tdVersementCumulatif" className="cell-num" headers="enteteVersementCumulatif">
                                        <ValeurAvecAucunCache valeur={this.props.versementCumulatif} />
                                    </td>
                                    <td id="tdMinimumLegal" className="cell-num" headers="enteteMinimumLegal">
                                        <ValeurAvecAucunCache valeur={this.props.minimumLegal} />
                                    </td>
                                    <td id="tdMaximumViager" className="cell-num" headers="enteteMaximumViager">
                                        <ValeurAvecAucunCache valeur={this.props.maximumViager} />
                                    </td>
                                    <td id="tdPortionImposable" className="cell-num" headers="entetePortionImposable">
                                        <ValeurAvecAucunCache valeur={this.props.portionImposable} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                )

    }
  }
