import React from 'react';
import I18nConfig from "./I18n";
import ValeurAvecAucunCache from './valeurAvecAucunCache';

export default class DetailsRenteReversible extends React.Component {

    render () {

            return (
                <div>
                    <h3 id="titreDetailsRenteReversible" className="sansMarginTop">{I18nConfig.obtenirRessource('detailsRenteReversible:TitreDetailsRenteReversible')}</h3>
                    <div className="table-responsive">
                        <table className="table tableau-donnees table-striped" id="tableauDetailsRenteReversible">
                            <thead>
                                <tr>
                                    <th id="enteteRenteConjointSurvivant" className="cell-texte" scope="col">
                                        {I18nConfig.obtenirRessource("detailsRenteReversible:RenteConjointSurvivant")}
                                    </th>
                                    <th id="entetePrestationSurvivant" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("detailsRenteReversible:PrestationSurvivant")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td id="tdRenteConjointSurvivant" className="cell-texte" headers="enteteRenteConjointSurvivant">
                                        <ValeurAvecAucunCache valeur={this.props.typeRenteReversible} />
                                    </td>
                                    <td id="tdPrestationSurvivant" className="cell-num" headers="entetePrestationSurvivant">
                                        <ValeurAvecAucunCache valeur={this.props.prestationRenteReversible} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                )

    }
  }
