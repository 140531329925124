import React from 'react';
import I18nConfig from "./I18n";
import ValeurAvecAucunCache from './valeurAvecAucunCache';

export default class FondsInteretGaranti extends React.Component {

    placementsInteretGaranti(props) {

        return (
            <div className="sectionMarginTop">
                <h3 id={"titreFondInteretGaranti_" + props.indexFond} className="sansMarginTop">{props.listePlacementInteretGaranti[0].titre}</h3>
                <div className="table-responsive">
                    <table className="table tableau-donnees table-striped" id={"tableauPlacementsInteretGaranti_" + props.indexFond}>
                        <thead>
                            <tr>
                                <th id={"enteteDateEffective_" + props.indexFond} className="cell-texte colonneDate" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:DateEffective")}
                                </th>
                                <th id={"enteteMontantInvesti_" + props.indexFond} className="cell-num" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:MontantInvesti")}
                                </th>
                                <th id={"enteteTauxInteret_" + props.indexFond} className="cell-num" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:TauxInteret")}
                                </th>
                                <th id={"enteteInteretGarantiDuree_" + props.indexFond} className="cell-texte oddTitle" scope="col" colSpan="2">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:Duree")}
                                </th>
                                <th id={"enteteTypeInteret_" + props.indexFond} className="cell-texte" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:TypeInteret")}
                                </th>
                                <th id={"enteteTraitementInteret_" + props.indexFond} className="cell-texte" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:TraitementInteret")}
                                </th>
                                <th id={"enteteInteretGarantiDateEcheance_" + props.indexFond} className="cell-texte colonneDate" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:DateEcheance")}
                                </th>
                                <th id={"enteteInteretGarantiValeurAccumulee_" + props.indexFond} className="cell-num" scope="col" rowSpan="2">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:ValeurAccumulee")}
                                </th>
                            </tr>
                            <tr>
                                <th id={"enteteInteretGarantiMois_" + props.indexFond} className="cell-num" scope="col">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:Mois")}
                                </th>
                                <th id={"enteteInteretGarantiJours_" + props.indexFond} className="cell-num" scope="col">
                                    {I18nConfig.obtenirRessource("placementInteretGaranti:Jours")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.listePlacementInteretGaranti.map((itemPlacementInteretGaranti, indexPlacementInteretGaranti) =>
                                <tr key={indexPlacementInteretGaranti}>
                                    <td id={"tdDateEffective_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-texte colonneDate" headers={"enteteDateEffective_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.dateEffective} />
                                    </td>
                                    <td id={"tdMontantInvesti_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-num" headers={"enteteMontantInvesti_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.montant} />
                                    </td>
                                    <td id={"tdTauxInteret_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-num" headers={"enteteTauxInteret_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.tauxInteret} />
                                    </td>
                                    {/* eslint-disable */}
                                    <td id={"tdInteretGarantiDureeMois_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-num" headers={"enteteInteretGarantiDuree_" + props.indexFond + " " + "enteteInteretGarantiMois_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.dureeMois} />
                                    </td>
                                    <td id={"tdInteretGarantiDureeJour_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-num" headers={"enteteInteretGarantiDuree_" + props.indexFond + " " + "enteteInteretGarantiJours_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.dureeJour} />
                                    </td>
                                    {/* eslint-enable */}
                                    <td id={"tdTypeInteret_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-texte" headers={"enteteTypeInteret_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.typeInteret} />
                                    </td>
                                    <td id={"tdTraitementInteret_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-texte" headers={"enteteTraitementInteret_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.traitementInteret} />
                                    </td>
                                    <td id={"tdInteretGarantiDateEcheance_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-texte colonneDate" headers={"enteteInteretGarantiDateEcheance_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.dateEcheance} />
                                    </td>
                                    <td id={"tdInteretGarantiValeurAccumulee_" + props.indexFond + "_" + indexPlacementInteretGaranti}
                                        className="cell-num" headers={"enteteInteretGarantiValeurAccumulee_" + props.indexFond}>
                                        <ValeurAvecAucunCache valeur={itemPlacementInteretGaranti.valeurAccumulee} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th id={"enteteTotal" + props.indexFond} scope="row">{I18nConfig.obtenirRessource("commun:Total")}</th>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td className="cell-num" aria-hidden="true"></td>
                                <td id={"tdInteretGarantiValeurAccumuleeTotale_" + props.indexFond} className="cell-num">{props.valeurAccumuleeTotale}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            )

    }

    render () {
        return (this.props.listeFondsInteretGaranti.map((itemFondInteretGaranti, indexFondInteretGaranti) =>
                    <div key={indexFondInteretGaranti}>
                        <this.placementsInteretGaranti typePlacement={itemFondInteretGaranti.typePlacement}
                                                       valeurAccumuleeTotale={itemFondInteretGaranti.valeurAccumuleeTotale}
                                                       listePlacementInteretGaranti={itemFondInteretGaranti.listePlacementInteretGaranti}
                                                       indexFond={indexFondInteretGaranti} />
                    </div>
                ))
    }
}
