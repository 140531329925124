import React from 'react';
import I18nConfig from "./I18n";

export default class ValeurAvecAucunCache extends React.Component {

    render () {
        if (!this.props.valeur)
            return <React.Fragment>
                    {this.props.valeur}<span className="sr-only">{I18nConfig.obtenirRessource("commun:ValeurAucun")}</span>
                </React.Fragment>
        else
            return this.props.valeur
    }
  }
