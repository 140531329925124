class Configuration {
  obtenirConfigurations() {

    const env =  typeof window !== "undefined" && typeof window["envvars"] !== "undefined" && typeof window["envvars"].REACT_APP_ENV !== "undefined"
    ? String(window["envvars"].REACT_APP_ENV)
    : "dev";

    const mockValue = {
      URL_BFF: 'https://api-azr-dev-d.desjardins.com/api/gpap/assurance-particuliers/epargne/presenterdetailsdetention',
      HOST_STS: 'https://id.dev.desjardins.com',
      CLIENTID_STS:  'grLa4rlH8Y5ttK4Z4rFCWNEWbEwQzpYs',
      URL_AUTHORIZE_PISE: 'https://api-azr-dev-d.desjardins.com/auth/oauth/v2/tokenx/authorize/gpap',
      CLIENTID_AUTHORIZE_PISE: 'l7d94cbe81266c474b94e72c169031658b',
      LISTE_URL_FR: 'https://details-detention-eprg-web.apps.cfzcea.dev.desjardins.com/',
      LISTE_URL_EN: 'https://details-detention-eprg-web-en.apps.cfzcea.dev.desjardins.com/',
      URL_WEBI: 'https://www.webi-fonc.dsf-dfs.com/webi/webitrans/'
    }

    const configs = {
      dev: mockValue,
      fonc: mockValue,
      qa: {
        URL_BFF: 'https://api-azr-dev-d.desjardins.com/api/gpap/assurance-particuliers/epargne/presenterdetailsdetention-qa',
        HOST_STS: 'https://id.dev.desjardins.com',
        CLIENTID_STS:  'grLa4rlH8Y5ttK4Z4rFCWNEWbEwQzpYs',
        URL_AUTHORIZE_PISE: 'https://api-azr-dev-d.desjardins.com/auth/oauth/v2/tokenx/authorize/gpap',
        CLIENTID_AUTHORIZE_PISE: 'l7d94cbe81266c474b94e72c169031658b',
        LISTE_URL_FR: 'https://details-detention-eprg-web-qa.apps.cfzcea.dev.desjardins.com/',
        LISTE_URL_EN: 'https://details-detention-eprg-web-en-qa.apps.cfzcea.dev.desjardins.com/',
        URL_WEBI: 'https://www.webi-fonc.dsf-dfs.com/webi/webitrans/'
      },
      certif: {
        URL_BFF: 'https://api-azr-pp.desjardins.com/api/gpap/assurance-particuliers/epargne/presenterdetailsdetention-certif',
        HOST_STS: 'https://id.certif.desjardins.com',
        CLIENTID_STS:  'RlsOzv35Kq6k9MIkIDsa5X74BvlQZU93',
        URL_AUTHORIZE_PISE: 'https://api-azr-pp.desjardins.com/auth/oauth/v2/tokenx/authorize/gpap',
        CLIENTID_AUTHORIZE_PISE: 'l7d94cbe81266c474b94e72c169031658b',
        LISTE_URL_FR: 'https://details-detention-eprg-web-certif.apps.cfzcea.dev.desjardins.com;https://detailcontratrenteparticulier.assurancevie.certif.desjardins.com',
        LISTE_URL_EN: 'https://details-detention-eprg-web-en-certif.apps.cfzcea.dev.desjardins.com;https://individualannuitydetail.lifeinsurance.certif.desjardins.com',
        URL_WEBI: 'https://www.webi-syst.dsf-dfs.com/webi/webitrans/'
      },
      preprod: {
        URL_BFF: 'https://api-azr-pp.desjardins.com/api/gpap/assurance-particuliers/epargne/presenterdetailsdetention-preprod',
        HOST_STS: 'https://id.certif.desjardins.com',
        CLIENTID_STS:  'RlsOzv35Kq6k9MIkIDsa5X74BvlQZU93',
        URL_AUTHORIZE_PISE: 'https://api-azr-pp.desjardins.com/auth/oauth/v2/tokenx/authorize/gpap',
        CLIENTID_AUTHORIZE_PISE: 'l7d94cbe81266c474b94e72c169031658b',
        LISTE_URL_FR: 'https://details-detention-eprg-web-preprod.apps.cfzcea.dev.desjardins.com/',
        LISTE_URL_EN: 'https://details-detention-eprg-en-web-preprod.apps.cfzcea.dev.desjardins.com/',
        URL_WEBI: 'https://www.webi-syst.dsf-dfs.com/webi/webitrans/'
      },
      prod: {
        URL_BFF: 'https://api-azr.desjardins.com/api/gpap/assurance-particuliers/epargne/presenterdetailsdetention',
        HOST_STS: 'https://id.desjardins.com',
        CLIENTID_STS:  'TXsRLuuOxHEMBseLzWqKH2SRR7zaL8al',
        URL_AUTHORIZE_PISE: 'https://api-azr.desjardins.com/auth/oauth/v2/tokenx/authorize/gpap',
        CLIENTID_AUTHORIZE_PISE: 'l7366fd4f3459d42db91e0bf9fcd865d61',
        LISTE_URL_FR: 'https://details-detention-eprg-web.apps.cfzcec.desjardins.com;https://details-detention-eprg-web.apps.cfzccc.desjardins.com;https://detailcontratrenteparticulier.assurancevie.desjardins.com',
        LISTE_URL_EN: 'https://details-detention-eprg-web-en.apps.cfzcec.desjardins.com;https://details-detention-eprg-web-en.apps.cfzccc.desjardins.com;https://individualannuitydetail.lifeinsurance.desjardins.com',
        URL_WEBI: 'https://www.webi.dsf-dfs.com/webi/webitrans/'
      },
    }[env];
    return {
      ENV: env,
      URL_BFF: configs.URL_BFF,
      HOST_STS: configs.HOST_STS,
      CLIENTID_STS: configs.CLIENTID_STS,
      URL_AUTHORIZE_PISE: configs.URL_AUTHORIZE_PISE,
      CLIENTID_AUTHORIZE_PISE: configs.CLIENTID_AUTHORIZE_PISE,
      LISTE_URL_FR: configs.LISTE_URL_FR,
      LISTE_URL_EN: configs.LISTE_URL_EN,
      URL_WEBI: configs.URL_WEBI
    }
  }
}
export default new Configuration();
