import React from 'react';
import I18nConfig from "./I18n";
import ValeurAvecAucunCache from './valeurAvecAucunCache';
import * as CacherColonnes from './cacherColonnes.js';

export default class DetailsCredirentiers extends React.Component {

    componentDidMount () {
        CacherColonnes.cacherColonnesVides("tableauDetailsCredirentiers", I18nConfig.obtenirRessource('commun:ValeurAucun'));
    };

    renderModifierInfoBancaire(itemCreditRentier, indexCreditRentier) {
        if (itemCreditRentier.infoBancaire){
            return <table align="right" id={"tdModifierInformationsBancaires_" + indexCreditRentier}>
            <tbody className="noBordersEtBgColorInherit">
                <tr className="noBorders">
                    <td className="noBorders">
                        <a href={this.props.urlWebi} target="_blank" rel="noopener noreferrer"aria-label={I18nConfig.obtenirRessource('commun:LibelleLienVersModifierInfosBancaire')}><b>{I18nConfig.obtenirRessource('detailsCredirentiers:ModifierInfoBancaire')}</b></a>
                    </td>
                </tr>
            </tbody>
        </table>
        }
    }

    render () {
            return (
                <div>
                    <h3 id="titreDetailsCredirentiers" className="sansMarginTop">{this.props.rentier && this.props.rentier.length > 0 ? I18nConfig.obtenirRessource('detailsCredirentiers:TitreDetailsPrestataire'): I18nConfig.obtenirRessource('detailsCredirentiers:TitreDetailsCredirentiers')}</h3>
                    <div className="table-responsive">
                        <table className="table tableau-donnees table-striped" id="tableauDetailsCredirentiers">
                            <thead>
                                <tr>
                                    <th id="enteteCredirentiers" className="cell-texte" scope="col">
                                        {this.props.rentier && this.props.rentier.length > 0 ? I18nConfig.obtenirRessource("detailsCredirentiers:Prestataire") : I18nConfig.obtenirRessource("detailsCredirentiers:Rentier")}
                                    </th>
                                    <th id="enteteVersementBrut" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("detailsCredirentiers:VersementBrut")}
                                    </th>
                                    <th id="entetePortionImposable" className="cell-num" scope="col">
                                        {I18nConfig.obtenirRessource("detailsCredirentiers:PortionImposable")}
                                    </th>
                                    <th id="enteteInformationsBancaires" className="cell-texte" scope="col">
                                        {I18nConfig.obtenirRessource("detailsCredirentiers:InformationsBancaires")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.creditsRentiers.map((itemCreditRentier, indexCreditRentier) =>
                                    <tr key={indexCreditRentier}>
                                        <td id={"tdCredirentier_" + indexCreditRentier} className="cell-texte" headers="enteteCredirentiers">
                                            <ValeurAvecAucunCache valeur={itemCreditRentier.nomComplet} />
                                        </td>
                                        <td id={"tdVersementBrut_" + indexCreditRentier} className="cell-num" headers="enteteVersementBrut">
                                            <ValeurAvecAucunCache valeur={itemCreditRentier.versementBrut} />
                                        </td>
                                        <td id={"tdPortionImposable_" + indexCreditRentier} className="cell-num" headers="entetePortionImposable">
                                            <ValeurAvecAucunCache valeur={itemCreditRentier.portionImposable} />
                                        </td>
                                        <td id={"tdCellInformationsBancaires_" + indexCreditRentier} className="cell-texte" headers="enteteInformationsBancaires">
                                            <span id={"tdInformationsBancaires_" + indexCreditRentier}><ValeurAvecAucunCache valeur={itemCreditRentier.infoBancaire} /></span>
                                            {this.renderModifierInfoBancaire(itemCreditRentier, indexCreditRentier)}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                )

    }
  }
