import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import "n3-ux/dist/lib/externe/bootstrap/3.4.0/css/bootstrap.min.css";
import "n3-ux/dist/lib/interne/fwd-bootstrap/3.3/css/fwd-bootstrap.css";
import "n3-ux/dist/lib/interne/fwd-bootstrap/3.3/css/d2-0.css";
import "n3-ux/dist/lib/externe/datatables/1.10.18/css/jquery.dataTables.min.css";

import "./components/normes-gpap.css";
import "./components/detailContrat.css";
import { withCookies } from "react-cookie";
import Index from "./pages/detailDetention";

window.jQuery = require("jquery");
window.$ = window.jQuery;

require("jquery-ui/dist/jquery-ui.min.js");
require("n3-ux/dist/lib/externe/bootstrap/3.4.0/js/bootstrap.min");
require("n3-ux/dist/lib/interne/fwd-bootstrap/3.3/js/cybermetrie.js");
require("n3-ux/dist/lib/externe/datatables/1.10.18/js/jquery.dataTables.min.js");

//const detailDetention = lazy(() => import("./pages/detailDetention"));
const auth = lazy(() => import("./pages/auth"));

class App extends React.Component {
  render() {
    return (
      <Router>
        <Suspense fallback={<div />}>
          <Switch>
              <Route
                exact
                path="/"
                render={() => <Index cookies={this.props.cookies} />}
              />
              <Route path="/auth" component={auth} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default withCookies(App);
