import React, { Component } from "react";
import I18n from "./I18n";

export default class extends Component {
    render() {

        var anneeEnCours = new Date().getFullYear();
        var copyright1 = I18n.t("footer:copyright1").replace("{0}", anneeEnCours);

        return (
            <div id="zonePiedDePage" className="zone-pied-de-page">
                <footer role="contentinfo" id="footerDiv">
                    <div className="zone-pied-de-page">
                        <div className="container">
                            <div id="zoneLegale" className="zone-legale-no-border" nav="true">
                                <nav role="navigation">
                                    <ul>
                                        <li >
                                            <a id="lienSecurite" tabIndex="0" target="_blank" rel="noopener noreferrer" href={I18n.t("footer:securiteUrl")}>
                                                {I18n.t("footer:securite")}
                                                <span className="sr-only">{I18n.t("footer:liensExternes")}</span>
                                            </a>
                                        </li>
                                        <li >
                                            <a id="lienConfidentialite" tabIndex="0" target="_blank" rel="noopener noreferrer" href={I18n.t("footer:lienConfidentialite")}>
                                                {I18n.t("footer:confidentialite")}
                                                <span className="sr-only">{I18n.t("footer:liensExternes")}</span>
                                            </a>
                                        </li>
                                        <li >
                                            <a id="lienJuridique" tabIndex="0" target="_blank" rel="noopener noreferrer" href={I18n.t("footer:lienJuridique")}>
                                                {I18n.t("footer:juridique")}
                                                <span className="sr-only">{I18n.t("footer:liensExternes")}</span>
                                            </a>
                                        </li>
                                        <li >
                                            <a id="lienPlaintes" tabIndex="0" target="_blank" rel="noopener noreferrer" href={I18n.t("footer:lienPlaintes")}>
                                                {I18n.t("footer:plaintes")}
                                                <span className="sr-only">{I18n.t("footer:liensExternes")}</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a id="lienAccessibilite" tabIndex="0" target="_blank" rel="noopener noreferrer" href={I18n.t("footer:lienAccessibilite")}>
                                                {I18n.t("footer:accessibilite")}
                                                <span className="sr-only">{I18n.t("footer:liensExternes")}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <p className="copyright">{ copyright1 }<br />{I18n.t("footer:copyright2")}</p>
                                <div className="logo-securite">
                                    <img id="logoSecurite" border="0" height="30" width="114" src={I18n.t("footer:imageLogoSecurite")} alt={I18n.t("footer:texteLogoSecurite")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
