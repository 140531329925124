import I18n from 'i18next';

var sectionInfosPolice_fr = require("../resources/fr/sectionInfosPolice.json");
var sectionInfosPolice_en = require("../resources/en/sectionInfosPolice.json");
var sectionPeriodeGarantie_fr = require("../resources/fr/sectionPeriodeGarantie.json");
var sectionPeriodeGarantie_en = require("../resources/en/sectionPeriodeGarantie.json");
var detailsCredirentiers_fr = require("../resources/fr/detailsCredirentiers.json");
var detailsCredirentiers_en = require("../resources/en/detailsCredirentiers.json");
var detailsBeneficiaires_fr = require("../resources/fr/detailsBeneficiaires.json");
var detailsBeneficiaires_en = require("../resources/en/detailsBeneficiaires.json");
var detailsRenteReversible_fr = require("../resources/fr/detailsRenteReversible.json");
var detailsRenteReversible_en = require("../resources/en/detailsRenteReversible.json");
var donneesGeneralesEncaissement_fr = require("../resources/fr/donneesGeneralesEncaissement.json");
var donneesGeneralesEncaissement_en = require("../resources/en/donneesGeneralesEncaissement.json");
var placementInteretGaranti_fr = require("../resources/fr/placementInteretGaranti.json");
var placementInteretGaranti_en = require("../resources/en/placementInteretGaranti.json");
var placementTermeLieAuMarche_fr = require("../resources/fr/placementTermeLieAuMarche.json");
var placementTermeLieAuMarche_en = require("../resources/en/placementTermeLieAuMarche.json");
var fondInteretQuotidien_fr = require("../resources/fr/fondInteretQuotidien.json");
var fondInteretQuotidien_en = require("../resources/en/fondInteretQuotidien.json");
var commun_fr = require("../resources/fr/commun.json");
var commun_en = require("../resources/en/commun.json");
var footer_fr = require("../resources/fr/footer.json");
var footer_en = require("../resources/en/footer.json");
var header_fr = require("../resources/fr/header.json");
var header_en = require("../resources/en/header.json");

var francais = Object.assign(sectionInfosPolice_fr, commun_fr, sectionPeriodeGarantie_fr, detailsCredirentiers_fr, detailsBeneficiaires_fr, detailsRenteReversible_fr,
    donneesGeneralesEncaissement_fr, placementInteretGaranti_fr, placementTermeLieAuMarche_fr, fondInteretQuotidien_fr, footer_fr, header_fr);
var anglais = Object.assign(sectionInfosPolice_en, commun_en, sectionPeriodeGarantie_en, detailsCredirentiers_en, detailsBeneficiaires_en, detailsRenteReversible_en,
    donneesGeneralesEncaissement_en, placementInteretGaranti_en, placementTermeLieAuMarche_en, fondInteretQuotidien_en, footer_en, header_en);

I18n.init({
    initImmediate: false,
    preload: ['fr', 'en'],
    resources: {
        fr: francais,
        en: anglais
    },

    fallbackLng: 'fr',
    debug: false,

    ns: ['sectionInfosPolice', 'commun', "sectionDetailCouvertures", "sectionDetailsBeneficiaires", "sectionDetailsFondsEpargne",
    "sectionNotes", "sectionValeursFinancieres", "footer", "header"],
    defaultNS: 'sectionInfosPolice',

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ','
    },

    react: {
        wait: true
    }
}, (err, t) => {
    if (err) {
        return console.log('something went wrong loading', err);
    } else return t('key');
});

I18n.obtenirRessource = (ressource) => {

    if (process.env.REACT_APP_ENV === "dev" && !I18n.exists(ressource)) {
        throw new Error("La ressource '" + ressource + "' n'existe pas en " + I18n.language);
    } else {
        return I18n.t(ressource)
    }
};

export default I18n;
