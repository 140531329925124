import React from 'react';

const dayNamesVides = [
    '', '', '', '', '', '', '',
    '', '', '', '', '', '', ''
];
const timeNamesVides =  [
    '', '', '', '', '', '', '', ''
];

export default class ValeurDateLongue extends React.Component {

    render () {

        var dateFormat = require('dateformat');
        var formatAffichage;

        if (this.props.langue === 'fr') {
            dateFormat.i18n = {
                dayNames: dayNamesVides,
                monthNames: [
                    '', '', '', '', '', '', '', '', '', '', '', '',
                    'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
                ],
                timeNames: timeNamesVides
            };
            formatAffichage = 'd mmmm yyyy';
        }
        else {
            dateFormat.i18n = {
                dayNames: dayNamesVides,
                monthNames: [
                    '', '', '', '', '', '', '', '', '', '', '', '',
                    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
                ],
                timeNames: timeNamesVides
            };
            formatAffichage = 'mmmm d, yyyy';
        }

        var dateAffichage = dateFormat(this.props.dateAfficher, formatAffichage);

        return (
                <React.Fragment>
                    {dateAffichage}
                </React.Fragment>
                );
    }
  }
