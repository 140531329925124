import React from 'react';

export default class PartiesLiees extends React.Component {

    render () {
        return <ul className="unstyled ulPartiesLiees">
                    {this.props.listeValeurs.map((itemValeur, indexValeur) =>
                        <li key={indexValeur} className="liPartiesLiees" id={"li" + this.props.typePartiesLiees + "_" + indexValeur}>{itemValeur}</li>
                    )}
                </ul>
    }
  }
